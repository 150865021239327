import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ChangePassword.css';

function ChangePassword() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [storedPassword, setStoredPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = () => {
      try {
        const jsonValue = localStorage.getItem('userData');
        if (jsonValue !== null) {
          const parsedData = JSON.parse(jsonValue);
          setUserData(parsedData);
          setStoredPassword(parsedData.password);
        }
      } catch (e) {
        console.error('Error fetching user data:', e);
      }
    };

    fetchUserData();
  }, []);

  const handleChangePassword = async () => {
    if (currentPassword !== storedPassword) {
      alert('Error: Current password is incorrect');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      alert('Error: New password and confirm password do not match');
      return;
    }
  
    setLoading(true);
    const passwordData = {
      id: userData.id,
      email: userData.email,
      currentPassword,
      password: newPassword,
    };
  
    try {
      const response = await axios.put('https://wosapi.essarsystems.co.in/wos_restapi', passwordData);
      console.log('Password change response:', response.data);
  
      if (response.data && response.data.message === "Password changed successfully") {
        const updatedUserData = { ...userData, password: newPassword };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        setUserData(updatedUserData);
        setStoredPassword(newPassword);
        alert('Success: Password updated successfully');
        navigate(-1); // Go back to the previous page
      } else {
        alert('Error: ' + (response.data.message || 'Failed to update password'));
      }
    } catch (error) {
      console.error('Error changing password:', error);
      if (error.response) {
        console.error('Server responded with status:', error.response.status);
        console.error('Response data:', error.response.data);
        alert('Error: ' + (error.response.data.message || 'Failed to update password'));
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Error: No response from the server');
      } else {
        console.error('Error setting up request:', error.message);
        alert('Error: Failed to update password');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="change-password-container">
      <h1 className="title">Change Password</h1>

      <div className="input-view">
        <label className="label">Current Password</label>
        <input
          type="password"
          placeholder="Enter your current password"
          className="input"
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
        />
      </div>

      <div className="input-view">
        <label className="label">New Password</label>
        <input
          type="password"
          placeholder="Enter your new password"
          className="input"
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
        />
      </div>

      <div className="input-view">
        <label className="label">Confirm New Password</label>
        <input
          type="password"
          placeholder="Re-enter your new password"
          className="input"
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword}
        />
      </div>

      <div className="button">
        <button className="update-button" onClick={handleChangePassword} disabled={loading}>
          {loading ? 'Updating...' : 'Update Password'}
        </button>
      </div>
    </div>
  );
}

export default ChangePassword;
