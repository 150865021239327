// import { getApp, getApps, initializeApp } from 'firebase/app';
// import { getAuth, initializeAuth, browserLocalPersistence } from 'firebase/auth';

// let firebaseApp;

// export const getFirebaseApp = () => {
//   // If the Firebase app is already initialized, return it
//   if (firebaseApp) {
//     return firebaseApp;
//   }

//   // Firebase configuration
//   const firebaseConfig = {
//     apiKey: "AIzaSyBQgg21gZI0PY0OTGiI2EdMq_LSCXQSboY",
//   authDomain: "world-odisha-society.firebaseapp.com",
//   projectId: "world-odisha-society",
//   storageBucket: "world-odisha-society.appspot.com",
//   messagingSenderId: "598976718001",
//   appId: "1:598976718001:web:a0fa5313269b6fbde26e90",
//   measurementId: "G-2XY1DW83T9"
//   };

//    // Initialize Firebase
//    const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

//    // Initialize Firebase Auth with browser persistence
//    initializeAuth(app, {
//      persistence: browserLocalPersistence
//    });
 
//    // Store the initialized app to avoid reinitialization
//    firebaseApp = app;
 
//    return app;
//  };
 
//  export const getFirebaseAuth = () => {
//    const app = getFirebaseApp();
//    return getAuth(app);
//  };



// firebaseConfig.js
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

let firebaseApp;

// Function to initialize and return Firebase app
export const getFirebaseApp = () => {
  // Check if Firebase app is already initialized
  if (firebaseApp) {
    return firebaseApp;
  }

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBQgg21gZI0PY0OTGiI2EdMq_LSCXQSboY",
    authDomain: "world-odisha-society.firebaseapp.com",
    projectId: "world-odisha-society",
    storageBucket: "world-odisha-society.appspot.com",
    messagingSenderId: "598976718001",
    appId: "1:598976718001:web:a0fa5313269b6fbde26e90",
    measurementId: "G-2XY1DW83T9"
  };

  // Initialize Firebase if not already initialized
  firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

  // Set authentication persistence
  getAuth(firebaseApp).setPersistence(browserLocalPersistence);

  return firebaseApp;
};

// Function to get Firebase Auth instance
export const getFirebaseAuth = () => {
  const app = getFirebaseApp();
  return getAuth(app);
};

// Function to get Firestore instance
export const getFirestoreDb = () => {
  const app = getFirebaseApp();
  return getFirestore(app);
};

export const getFirebaseMessaging = () => {
  const app = getFirebaseApp();
  return getMessaging(app);
};
