import React, { useState } from 'react';
import MemberList from './MemberList'; // Adjust the import path as needed
import Chat from './Chat'; // Adjust the import path as needed
import './ChatPage.css'; // Create a CSS file for styling
import Navbar from '../Components/Navbar';

const ChatPage = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [showChat, setShowChat] = useState(false);

    const handleChatSelect = (receiverId, countryName, memberName) => {
        setSelectedChat({ receiverId, countryName, memberName });
        setShowChat(true); // Show chat on mobile
    };

    const handleBackButtonClick = () => {
        setShowChat(false); // Show member list on back button click
    };

    return (
        <div>
            {/* <Navbar /> */}
            <div className="chatPageContainer">
                <div className={`member-list-container ${showChat ? 'hide' : ''}`}>
                    <MemberList onSelectChat={handleChatSelect} />
                </div>
                <div className={`chat-container ${showChat ? 'show' : ''}`}>
                    {selectedChat ? (
                        <>
                            <button className="backButton" onClick={handleBackButtonClick}>Back to Member List</button>
                            <Chat
                                receiverId={selectedChat.receiverId}
                                countryName={selectedChat.countryName}
                                memberName={selectedChat.memberName}
                            />
                        </>
                    ) : (
                        <div className="selectChatMessage">
                            Select a chat to start messaging
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
