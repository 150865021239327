import React, { useState } from 'react';
import './BenefitsPopup.css';

const BenefitsPopup = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className="benefits-container">
      <button onClick={() => setModalVisible(true)} className="benefits-link">
        Benefits for Members
      </button>

      {modalVisible && (
        <div className="modal-background">
          <div className="modal-container">
            <button onClick={() => setModalVisible(false)} className="close-button">Close</button>
            <h2 className="heading">Benefits for Members</h2>
            <p className="benefit-text">INTERACTION: The opportunity to interact channel for direct communication with the leading Odisha centric or organizations and individuals in the world.</p>
            <p className="benefit-text">STRATEGY: The ability to provide strategic directions to the Consortium designated to shape up the Activities, proposals, policies, missions and visions of WOS.</p>
            <p className="benefit-text">PARTICIPATION: Participation in WOS Working Groups, Interest Groups, Cultural Groups, Seminars, Webinars, Celebrations, Ceremonies, Annual General Meetings, Filing of Nominations and Election.</p>
            <p className="benefit-text">LEADERSHIP: Demonstrate leadership through a commitment to establish the vitality of the Odia diaspora.</p>
            <p className="benefit-text">FORUM FOR IDEAS: WOS is an open forum to exchange ideas find out feasible solutions nonissues and provide insights from around the world of leaders.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BenefitsPopup;
