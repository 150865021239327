import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DownloadCertificateScreen.css'; // Import your CSS file
import Navbar from '../Components/Navbar';

const DownloadCertificateScreen = () => {
  const { userId } = useParams(); // Retrieve user ID from URL params
  const [showWebView, setShowWebView] = useState(false); // State to toggle WebView visibility
  const [certificateUrl, setCertificateUrl] = useState('');

  useEffect(() => {
    // Set the certificate URL with the userId parameter
    if (userId) {
      setCertificateUrl(`https://essarsystems.co.in/woscert/index.php?id=${userId}`);
    }
  }, [userId]);

  const handleLoadCertificate = () => {
    setShowWebView(true);
  };

  return (
    <div>
    {/* <Navbar /> */}
    <div className="certcontainer">
      <div className="container1">
        <h1 className="heading">Download WOS Certificate</h1>
        <p className="description">
          Click the below button to download your WOS Certificate.
        </p>
        <button className="btn" onClick={handleLoadCertificate}>
          Load Certificate
        </button>
        {showWebView && certificateUrl && (
          <iframe
            src={certificateUrl}
            className="webview"
            title="Certificate"
          />
        )}
      </div>
    </div>
    </div>
  );
};

export default DownloadCertificateScreen;
