import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Avatar from 'react-avatar';
import { FaCamera } from 'react-icons/fa';
import './UpdateProfile.css';
import Navbar from '../Components/Navbar';

function UpdateProfile() {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    photo: '',
    title: '',
    fname: '',
    lname: '',
    email: '',
    gender: '',
    mobile: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    addr1: '',
    addr2: '',
    pincode: '',
    membership_type: '',
    status: '',
    pstatus: '',
    apstatus: '',
    ismail: '',
    memno: '',
    certificate_date: ''
  });

  useEffect(() => {
    if (location.state && location.state.data) {
      const userData = location.state.data;
      setFormData(userData);
    } else {
      getUserData();
    }
  }, [location.state]);

  const handleImagePickerResponse = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({ ...prevState, photo: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const showImagePickerOptions = () => {
    setModalVisible(true);
  };

  const getUserData = async () => {
    try {
      const data = localStorage.getItem('userData');
      if (data !== null) {
        const parsedData = JSON.parse(data);
        setUserData(parsedData);
        setFormData(parsedData);
      } else {
        console.log('No user data found in localStorage');
      }
    } catch (error) {
      console.error('Error retrieving user data:', error);
    }
  };

  const updateProfile = async () => {
    const updatedUserData = { ...userData, ...formData };

    try {
      const response = await axios.put('https://wosapi.essarsystems.co.in/wos_restapi', updatedUserData);
      if (response.status === 200 && response.data) {
        console.log('Response from backend API:', response.data);
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        navigate('/profile', { state: { updatedData: updatedUserData } });
      } else {
        alert('Update Failed', 'Failed to update profile. Please try again.');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Error', 'An error occurred while updating your profile. Please try again later.');
    }
  };

  return (
    <div className="update-profile">
      <div>
        {/* <Navbar /> */}
      </div>
      <div className="container1">
        {/* Profile Picture */}
        <div className="profile-picture-container">
          <label className="avatar-wrapper" htmlFor="file-input">
            <Avatar
              size="130"
              className="avatar"
              src={formData.photo || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAM1BMVEXFzeD////Byt7L0uPByd7Q1+b7/P3j5/Dv8fbe4+3r7vTFzuDL0+P19/rn6/LZ3urW2+lU+LHUAAAFLklEQVR4nO2dC3arMAxEQXwCcfjsf7XPkLw2tEka5AEziu8CeuKpJVmyLLIskUgkEkdFbsT+HXEQKbNqOPWN59y72D9nd/z/vWqbOv/mozSY9n116vIl1acYg1++G9v+5/rzvMs+QwL/7x/O9a/lT5zL2D9uF7wAzcP1e+pP2AQi4/mZAJ6TfQ3EtY9N4D+jdQ2k6F8K4OltayDFKyP4cghmI6PzVvDnHrDuEqR9UwFPY1IEufw+C72yh8LeIUFOaxSY6K0dFt2qTXDDVJCUi0IBT2vHHmTUSWAnPjgZtBJ4p2BjJ4RIYCSHlCpEAi+CAXMowiSwIIJoguKSE7k5rD8aPWDg3gnKg8EPLrGXEUL5tGC2ijr2OkIIjAlfEJdVBLMNcmprQEnAW09YUzT5C9aNADgbfMGaPQlOgrwj1cAlDZIGGVYD2ktIpAasiRNQgzxpkOektoCMjUkDT+zFaEFqwNqohtSgiL0YHcHlVAMaoCooM6SJo/qK7RGk+yBpkGVBl2w2NAi7aEwamNEAWE5MGiQNkgZJg6RB0sCEBoj+C3YN0j5IGkyks3LKnSegdaSkQdIgaUCtwcf7RJHy02OjVG3/+knvSlxJd+uK7Emb6eqOrQVBoJvgCtu16xYasF23QXsPWDVI+yArN9CALTyW6LhAqAE8NuaEcQH2fOMbtkNS+e7IC8MaYIuJM3TnRGwxcYbvPQ+0eDBD95TFIRv3rwyx17Qa/EGRbmqSAz1xvSP2ktaDvW3MOV9xoJ0i43tftEPgc4n4U1Ls9ajAbgTOkSCh02AW1GxJ4w2gCKwSIAspF0pLmIB5BNaXvhnwnMSXMn6DqrBzBoUrqKoiXdp8B6qqWMVeSADyzijhNyDeBiinyOwSUc95uAemYZ66sl0wLYGcFPmK6gsgCTRzZJxAlJe5TQFyQiA3hQxRVuSOChPBXrEW2trBf/RDts1sg+C8iXZA1oKwc9IY++dDCDojUKcKd5T67JF6ou4C9SHBhjO4os2hiWupv1Hm0JY00LpFKx5xQmsLpjRQdisy19R/om3MsaSB9rxsSgOdBKY00E5SZOxBeoa2kGJJA+01gyEN1JmjJQ20jxnYq+p3qPNGQxqo66qtHQ3UfUlJA0MalKJ+8NnyPfh/hFzOnbpFr6vP7JeNGaALw0BJMfzemT4+IhqSYq8hFESDInNj3ky4BPSXroieLPZDAuI7nuROsUS84iAvqKmT5gWxVxEIQgJuY8BsA+6NgPmyMXVkQHXuM+cMuBEIjO98Z4K78r5pOFtVpWiRn7Qd+aop5QU9AqJuMyYVRKoNJkT58OD/cuy1vYUX4LTBvLgrzVAcXwYpthPgSjcc2ybkgjoRvKQvjqrCVl7gEU11RJMQGTeYFvicbjyaCnsrMFG3R1JBsnZjR/hEhf4gJiHi0NOg1nCOL8OejvAJ3RBTBScy7O4GHlCfXCwV4hrBkvMlQmYpZXQjWLJ7sJTyEEawZNfMsowUC/+m38kxiNtgbDCMZgfHIMUuaVEA3cYnBnx5aAu8e9xMASkYFJjoNpo/K+7oVnBPg68xuKw8zoHoPXp0pCzHg0bDV0CTa3EsjmBJjUunsB9u35Ua08wkGecmuIEIEVIReoIFwTf38JHhEQgcxuqOlx4qCBFBCnY7uKH/uhV0SHRU9CNFUO1EB0A9TMKIIczoggP+QxpRUQ0cM+MMrmiezG7x0bmoKDYCZhLqgVjf8WvhfLhkfaPnFt/di8zq6XNbfIczMqsHDW3xTdrYPFvrP7kiUsVMV4ODAAAAAElFTkSuQmCC'}
            />
            <div className="camera-icon-container">
              <FaCamera size={22} color="white" />
            </div>
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            onChange={handleImagePickerResponse}
            style={{ display: 'none' }}
          />
        </div>

        {/* Form Fields */}
        <div style={{ marginTop: 50, marginHorizontal: 22 }}>
          <div className="info-edit-view">
            <span className="info-edit-first-text">First name</span>
            <input
              placeholder="Your FirstName"
              className="info-edit-second-text"
              onChange={(e) => setFormData({ ...formData, fname: e.target.value })}
              value={formData.fname}
            />
          </div>
          <div className="info-edit-view">
            <span className="info-edit-first-text">Last name</span>
            <input
              placeholder="Your LastName"
              className="info-edit-second-text"
              onChange={(e) => setFormData({ ...formData, lname: e.target.value })}
              value={formData.lname}
            />
          </div>
          <div className="info-edit-view">
            <span className="info-edit-first-text">Email</span>
            <input
              placeholder="Your Email"
              className="info-edit-second-text"
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              value={formData.email}
              readOnly
            />
          </div>
          <div className="info-edit-view">
            <span className="info-edit-first-text">Mobile No</span>
            <input
              placeholder="Your Mobile No"
              className="info-edit-second-text"
              type="tel"
              maxLength={10}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              value={formData.mobile}
            />
          </div>
        </div>

        {/* Update Button */}
        <div className="button">
          <button className="update-button" onClick={updateProfile}>
            Update Profile
          </button>
        </div>

        <div className="password">
          <button onClick={() => navigate('/change-password')} className="in-but">
            <span style={{ color: '#0163D2', fontSize: 16 }}>Change Password</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
