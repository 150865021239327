import React, { createContext, useState, useEffect } from 'react';

// Creating the context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // State to hold user data
  const [userData, setUserData] = useState(null);

  // Effect to load user data from localStorage on component mount
  useEffect(() => {
    const loadUserData = () => {
      try {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          console.log('Loaded user data:', storedUserData);
          setUserData(JSON.parse(storedUserData));
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };
    loadUserData();
  }, []); // Empty dependency array ensures this effect runs only once

  // Function to update user data and save it to localStorage
  const updateUserData = (newData) => {
    setUserData(newData);
    try {
      localStorage.setItem('userData', JSON.stringify(newData));
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  // Function to handle login
  const login = (newUserData) => {
    try {
      localStorage.setItem('userData', JSON.stringify(newUserData));
      setUserData(newUserData);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  // Function to handle logout
  const signOut = () => {
    try {
      localStorage.removeItem('userData');
      setUserData(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Providing the user context to its children
  return (
    <UserContext.Provider value={{ userData, updateUserData, login, signOut }}>
      {children}
    </UserContext.Provider>
  );
};
