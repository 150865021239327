import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentPage = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        id: '',
        fname: '',
        email: '',
        mobile: '',
        membershipType: '',
        status: '',
        apstatus: '',
        isemail: ''
    });

    useEffect(() => {
        const getUserData = () => {
            try {
                const userDataString = localStorage.getItem('userData');
                console.log('Raw user data string from localStorage:', userDataString);
                if (userDataString) {
                    const parsedUserData = JSON.parse(userDataString);
                    console.log('Parsed user data:', parsedUserData);
                    const { id, email, membership_type, status, apstatus, isemail } = parsedUserData;
                    if (id && email && membership_type && status && apstatus && isemail !== undefined) {
                        setUserData({
                            id,
                            email,
                            membershipType: membership_type,
                            status,
                            apstatus,
                            isemail
                        });
                    } else {
                        alert('Invalid user data');
                    }
                } else {
                    alert('User data not found');
                }
            } catch (error) {
                console.error('Error retrieving user data:', error);
                alert('An error occurred while retrieving user data');
            }
        };
        getUserData();
    }, []);

    const getAmount = (membershipType) => {
        switch (membershipType) {
            case 'Annual (INR 3,000/-)': return 3000;
            case 'Life (INR 15,000/-)': return 15000;
            case 'Premium Life (INR 25,000/-)': return 25000;
            case 'Corporate (INR 1,000,00/-)': return 100000;
            case 'Donor (INR 1,000,00/- & Above Contribution)': return 100000;
            case 'Institution/ Organisation (INR 5,000,00/-)': return 500000;
            default: return 0;
        }
    };

    const amount = getAmount(userData.membershipType);

    const handlePayment = () => {
        console.log('User data:', userData);
        console.log('Amount:', amount);

        if (window.Razorpay) {
            const options = {
                key: 'rzp_test_eODGTICdyVLxom',
                amount: amount * 100, // Convert to paisa
                currency: 'INR',
                name: 'Membership Payment',
                description: 'Membership Fee',
                prefill: {
                    email: userData.email,
                    contact: userData.mobile,
                    name: userData.fname,
                },
                theme: { color: '#007bff' },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();

            razorpay.on('payment.failed', async (error) => {
                console.error('Payment failed:', error);
                alert('Payment failed');
            });

            razorpay.on('payment.success', async (data) => {
                console.log('Payment success:', data);
                alert('Payment successful');
                try {
                    await axios.put(`https://wosapi.essarsystems.co.in/wos_restapi`, {
                        email: userData.email,
                        status: userData.status,
                        apstatus: userData.apstatus,
                    });

                    if (userData.isemail === 'N') {
                        try {
                            await axios.post(`https://essarsystems.co.in/woscert/addrec/`, {
                                email: userData.email,
                            });
                        } catch (error) {
                            console.error('Error in additional requests:', error);
                            alert('Failed to complete additional actions.');
                        }
                    }

                    window.location.href = '/profile'; // Redirect to Profile Page
                } catch (error) {
                    console.error('Error updating pstatus:', error);
                }
            });
        } else {
            console.error('Razorpay script not loaded');
            alert('Razorpay script not loaded');
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Payment Details</h1>
            <p>Membership Type: {userData.membershipType || 'No membership type available'}</p>
            <p>Amount: {amount} INR</p>
            <button style={styles.payButton} onClick={handlePayment}>
                Proceed To Pay
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px', // Adjusted padding for smaller screens
        textAlign: 'center',
        height: '100vh',
        boxSizing: 'border-box',
    },
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    payButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        padding: '12px 24px',
        borderRadius: '5px',
        marginTop: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
    },
};

// Media queries for responsiveness
const responsiveStyles = {
    container: {
        padding: '20px',
    },
    heading: {
        fontSize: '20px',
    },
    payButton: {
        padding: '10px 20px',
        fontSize: '14px',
    },
};

// Apply responsive styles using media queries
const stylesWithMediaQueries = {
    ...styles,
    '@media (max-width: 768px)': {
        container: {
            padding: '15px',
        },
        heading: {
            fontSize: '22px',
        },
        payButton: {
            padding: '10px 18px',
            fontSize: '15px',
        },
    },
    '@media (max-width: 480px)': {
        container: {
            padding: '10px',
        },
        heading: {
            fontSize: '20px',
        },
        payButton: {
            padding: '8px 16px',
            fontSize: '14px',
        },
    },
};

export default PaymentPage;