import React, { useState } from 'react';
import './TermsAndConditionsPopup.css';

const TermsAndConditionsPopup = ({ onAgreementChange }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);

    const handleCheckboxChange = () => {
        setAgreeTerms(true);
        onAgreementChange(true);
        setIsVisible(false); // Close the popup after agreeing to the terms
    };

    return (
        <div className="term-container">
           <span className="link" onClick={() => setIsVisible(true)}>
    Terms and Conditions
  </span>

            {isVisible && (
                <div className="modal-background">
                    <div className="modal-container">
                        <div className="scroll-container">
                            <h2 className="heading">Terms and Conditions</h2>
                            <h3 className="title">Membership of World Odia Society (WOS):</h3>
                            <p className="text">
                            Members constitute the core and they are the true ambassadors of the organization.
                                In line with the vision and mission and objectives of “World Odia Society”, it is
                                prudent to pursue excellence in promotion and propagation of Odia culture by bringing
                                together all Odias across the globe under one umbrella and work on all round development
                                of our state in the area of social, economic, education, culture and tradition, women
                                empowerment, uplift the standard of living, support to needy people with sustainability
                                and integrity to make it progressive and vibrant Odisha. To accomplish this mission,
                                membership plays a vital role so as to provide a mutually supportive environment for
                                the better interaction of Odias residing in different corners of world and in the motherland
                                as well, through friendship and fellowship to foster a balanced and harmonious society
                                through promotion of creativity and celebration of Odia cultural values and ethos.
                            </p>
                            <p className="text">
                            The member shall adhere to the core values such as respect religion, dignity and culture of the
                                fellow members, maintain integrity and demonstrate ethical conduct and subscribe to the visions,
                                mission, objectives and provisions prescribed in the By-laws of World Odia Society (WOS). The
                                member shall take pride in being an Odia and hailing from his motherland Odisha. The members
                                of WOS shall take active interest in promoting activities in the understanding of the Odia culture
                                and enrich Odia heritage through cultural, social, educational and developmental activities. The
                                members shall be connected to Odisha and shall have willingness and skills to participate in overall
                                development of our state. The integrity of member of WOS shall be non-communal, non-religious
                                and possessing non- profit making motive and shall have no intention to utilise the name of WOS
                                for his/her personal interest or gain/mileage.
                            </p>
                            <h3 className="title">WOS membership criteria:</h3>
                            <p className="subtitle">Following criteria are laid down to become a member of World Odia Society:</p>
                            <p className="text">• The regular membership of WOS shall be open to all Odias of age 18 years or more residing
                                across the globe whether inside Odisha or outside Odisha with special focus particularly on
                                Odias residing in different countries outside India and in different states of India outside
                                Odisha. Apart from the above, Odias, any other person keen to work for the development of
                                Odisha may be considered for membership of WOS.</p>
                            <p className="text"> • WOS shall encourage and accord membership to certain dynamic people, activists,
                                dignitaries residing in Odisha to reach out to grass root level, understand issues and
                                implement in Odisha. For this reason, representatives from each district, Industrial and
                                Urban belts may be considered for membership.</p>
                            <p className="text"> • Apart from the regular membership of WOS, there shall be a provision of giving honorary
                                membership to the notable and reputed Odias either residing in Odisha or outside Odisha
                                as their representation and blessings will contribute in guiding WOS and help in bringing our
                                projects to life smoothly through their influence. Any regular member of WOS may propose
                                the name of the person of repute for honorary membership to the committee. However, the
                                decision to accord honorary membership lies with the membership working committee of
                                WOS.</p>
                            <p className="text"> • WOS membership shall be broadly categorized into regular annual membership, life
                            membership, family membership, honorary membership and patrons.</p>
                            <p className="text">• Regular Annual membership shall be renewed every year and maximum 3 months grace
                                period shall be provided for renewal process. Upon completion of the grace period the
                                members shall loss their membership benefits.</p>
                            <p className="text">• Request for the new membership or renewal of membership shall be submitted to the
                                “Membership working committee” of WOS chaired by Chairman of WOS using prescribed
                                application form. This form either may be of digital link form or hard copy. Duly filled forms
                                shall be submitted to WOS mail id or handover in person to the membership working
                                committee. However, the committee reserves the right to verify the information provided in
                                the application form and accord membership.</p>
                            <p className="text">• The Regular annual membership contribution shall be INR 1000/- per year. Non-payment of
                            membership is liable for withdrawal of membership.</p>
                            <p className="text">• Life time membership contribution shall be INR 10,000/- for entire life...</p>
                            <p className="text">• Once the membership of someone is cancelled, to reactivate the same the member shall have to pay additional amount...</p>
                            <p className="text">• There shall be no membership fees for Patrons and honorary members.</p>
                            <p className="text"> • Husband and wife and their children more than 18 years are eligible for family membership
                            of WOS. In family case the annual membership is INR 2000/-.</p>
                            <p className="text">• The membership once paid, shall not be refunded under any circumstances.</p>
                            <p className="text"> • All paid members shall have the rights to participate in the selection of executive body or
                            can nominate themselves for core committee members.</p>
                            <p className="text">• Many countries and many states do have more Odia population residing there and also may
                                be multiple Odia associations. These individual associations or country shall have minimum
                                of 100+ members. Such association can nominate 3 members from their association to be
                                the members of WOS. The associations having 25 to 100 members can nominate 2
                                members from their associations including their head of the association. Countries or
                                associations having less than 25 members can be represented by one member from their
                                country or associations to WOS. These members may change as per the selection process
                                and tenure of their respective associations. Such initiative may lead This may lead to a
                                positive outcome and unite all the organizations of respective countries.</p>
                            <p className="text"> • The member shall have the rights to nominate himself/herself to any of the core or functional
                                working committee of WOS mentioning his interest and capabilities and his past record in
                                engaging or pursuing such activities.</p>
                            <p className="text"> • Resignation of membership will be accepted from any member at any time by submitting the
                                request to executive body/core committee and feedback from the resigned members shall
                                be honoured and viewed constructively.</p>
                            <p className="text"> • WOS reserves right to cancel membership of any person without any notification. However,
                                the cancellation of any particular member can be done by the executive body/core
                                committee with 2/3rd majority.</p>
                            <p className="text"> • It is the duty and responsibility of the members to contribute in their own way within their
                                ethical limit, respect other members’ views and actively participate in all activities so as to
                                maintain and enhance the honour, image and happiness and health of WOS.</p>
                            <p className="text">• WOS members shall behave professionally and appropriately in all events, forums,
                            discussions and communications.</p>
                            <p className="text">• WOS membership working committee shall ensure proper representation of Odias residing
                            in various parts of the world, i.e. all the countries to strengthen WOS.</p>
                            <p className="text"> • The decision of WOS membership working committee is final and binding with regard to the
                            subject of membership.</p>

                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={agreeTerms}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="checkbox-label">I agree to the terms and conditions</label>
                            </div>
                        </div>
                        <button className="close-button" onClick={() => setIsVisible(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TermsAndConditionsPopup;
