import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './style.css'; // Assuming you have the styles in a CSS file
import {
  FaUser,
  FaLock,
  FaMobileAlt,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaCheckCircle,
  FaExclamationCircle,
} from 'react-icons/fa';

function RegisterPage() {
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [fnameVerify, setFNameVerify] = useState(false);
  const [lnameVerify, setLNameVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [emailVerify, setEmailVerify] = useState(false);
  const [mobile, setMobile] = useState('');
  const [mobileVerify, setMobileVerify] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  function handleSubmit() {
    const userData = {
      fname: fname,
      lname: lname,
      email,
      mobile,
      password,
      status: 'S',
    };
    console.log('userData', userData);

    if (fnameVerify && lnameVerify && emailVerify && passwordVerify && mobileVerify) {
      axios
        .post('https://wosapi.essarsystems.co.in/wos_restapi', userData, {
          headers: {
            status: 'S',
          },
        })
        .then(res => {
          console.log(res.data);
          if (res.data.status === 'ok') {
            alert('Registered Successfully!!');
            navigate('/');
          } else {
            alert('Registered Successfully!!');
            navigate('/');
          }
        })
        .catch(e => {
          console.error(e.response ? e.response.data : e.message);
          alert(`An error occurred: ${e.message}`);
        });
    } else {
      alert('Fill mandatory details');
    }
  }

  function handleFName(e) {
    const fnameVar = e.target.value;
    setFName(fnameVar);
    setFNameVerify(fnameVar.length > 1);
  }

  function handleLName(e) {
    const lnameVar = e.target.value;
    setLName(lnameVar);
    setLNameVerify(lnameVar.length > 1);
  }

  function handleEmail(e) {
    const emailVar = e.target.value;
    setEmail(emailVar);
    setEmailVerify(/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(emailVar));
  }

  function handleMobile(e) {
    const mobileVar = e.target.value;
    setMobile(mobileVar);
    setMobileVerify(/[6-9]{1}[0-9]{9}/.test(mobileVar));
  }

  function handlePassword(e) {
    const passwordVar = e.target.value;
    setPassword(passwordVar);
    setPasswordVerify(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(passwordVar));
  }

  return (
    <div className="container">
      <div className="logo-container">
        <img src={require('../../assets/logo_dark.png')} alt="Logo" className="logo" />
      </div>
      <div className="form-container">
        <h1 className="header-text">Subscribe Now</h1>
        <div className="input-container">
          <FaUser className="icon" />
          <input
            type="text"
            placeholder="First Name"
            value={fname}
            onChange={handleFName}
            className="text-input"
          />
          {fname.length > 0 && (fnameVerify ? <FaCheckCircle className="valid-icon" /> : <FaExclamationCircle className="error-icon" />)}
        </div>
        {fname.length > 0 && !fnameVerify && <p className="error-text">Name should be more than 1 character.</p>}
        <div className="input-container">
          <FaUser className="icon" />
          <input
            type="text"
            placeholder="Last Name"
            value={lname}
            onChange={handleLName}
            className="text-input"
          />
          {lname.length > 0 && (lnameVerify ? <FaCheckCircle className="valid-icon" /> : <FaExclamationCircle className="error-icon" />)}
        </div>
        {lname.length > 0 && !lnameVerify && <p className="error-text">Name should be more than 1 character.</p>}
        <div className="input-container">
          <FaEnvelope className="icon" />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmail}
            className="text-input"
          />
          {email.length > 0 && (emailVerify ? <FaCheckCircle className="valid-icon" /> : <FaExclamationCircle className="error-icon" />)}
        </div>
        {email.length > 0 && !emailVerify && <p className="error-text">Enter Valid Email Address</p>}
        <div className="input-container">
          <FaMobileAlt className="icon" />
          <input
            type="text"
            name="mobile_avoid_autofill"
            placeholder="Mobile"
            value={mobile}
            onChange={handleMobile}
            maxLength={10}
            className="text-input"
          />
          {mobile.length > 0 && (mobileVerify ? <FaCheckCircle className="valid-icon" /> : <FaExclamationCircle className="error-icon" />)}
        </div>
        {mobile.length > 0 && !mobileVerify && <p className="error-text">Enter a valid phone number</p>}
        <div className="input-container">
          <FaLock className="icon" />
          <input
            type={showPassword ? 'text' : 'password'}
            name="new-password"
            placeholder="Password"
            value={password}
            onChange={handlePassword}
            className="text-input"
          />
          <button onClick={() => setShowPassword(!showPassword)} className="toggle-password">
            {showPassword ? <FaEyeSlash className={passwordVerify ? 'valid-icon' : 'error-icon'} /> : <FaEye className={passwordVerify ? 'valid-icon' : 'error-icon'} />}
          </button>
        </div>
        {password.length > 0 && !passwordVerify && <p className="error-text">Uppercase, Lowercase, Number and 6 or more characters.</p>}
        <div className="button-container">
          <button className="submit-button" onClick={handleSubmit}>Subscribe</button>
        </div>
        <div className="already-registered">
          Already registered? <a href="/">Login here</a>
        </div>

      </div>
    </div>
  );
}

export default RegisterPage;
