import React, { createContext, useState, useEffect } from 'react';
import { getFirebaseMessaging } from '../Utils/firebaseInitialization'; // Import from firebaseConfig
import { getToken, onMessage } from 'firebase/messaging';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [fcmToken, setFcmToken] = useState('');
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  // Load notifications from localStorage on initialization
  useEffect(() => {
    try {
      const storedNotifications = localStorage.getItem('notifications');
      if (storedNotifications) {
        const parsedNotifications = JSON.parse(storedNotifications);
        console.log('Loaded notifications from localStorage:', parsedNotifications); // Debugging log
        setNotifications(parsedNotifications);
        // Set unread notifications from stored notifications
        setUnreadNotifications(parsedNotifications.filter(notification => !notification.read));
      } else {
        console.log('No notifications found in localStorage'); // Debugging log
      }
    } catch (error) {
      console.error('Error loading notifications from localStorage:', error); // Error handling log
    }
  }, []);

  // Save notifications to localStorage whenever they are updated
  useEffect(() => {
    try {
      console.log('Saving notifications to localStorage:', notifications); // Debugging log
      localStorage.setItem('notifications', JSON.stringify(notifications));
    } catch (error) {
      console.error('Error saving notifications to localStorage:', error); // Error handling log
    }
  }, [notifications]);

  useEffect(() => {
    const requestUserPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const messaging = getFirebaseMessaging();
          const token = await getToken(messaging, {
            vapidKey: 'BGXD537zXFyZXQabaEcdAX7IApBlKCotM1JrcBD_pwpRaALJcNvHKgmcdY0wrBXbOfYsHnQAo2pzehDtEQ448Nk' // Replace with your VAPID key
          });
          setFcmToken(token);
          console.log('FCM Token:', token);
        } else {
          console.log('Notification permission not granted.');
        }
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    requestUserPermission();

    const messaging = getFirebaseMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received:', payload); // Debugging log
      const { notification } = payload;
      if (notification) {
        // Add notification to the list and mark it as unread
        const newNotification = { ...notification, read: false, timestamp: new Date().toISOString() };
        setNotifications(prev => [...prev, newNotification]);
        setUnreadNotifications(prev => [...prev, newNotification]);
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to mark all notifications as read
  const markAllAsRead = () => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification => ({
        ...notification,
        read: true,
      }))
    );
    setUnreadNotifications([]); // Clear the unread notifications list
  };

  return (
    <NotificationContext.Provider value={{ notifications, fcmToken, unreadNotifications, markAllAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};
