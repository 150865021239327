import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Hidden from '@mui/material/Hidden';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import ProfileIcon from '@mui/icons-material/Person';
import ApplyIcon from '@mui/icons-material/PersonAdd';
import CertificateIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/Chat';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from '../assets/logo_dark.png';
import './Navbar.css';
import { NotificationContext } from '../Screens/NotificationContext';

const initialNavItems = [
  { icon: <ProfileIcon className="icon" />, label: 'Profile', navigateTo: '/profile' },
  { icon: <ApplyIcon className="icon" />, label: 'Apply For Member', navigateTo: '/member-form' },
  { icon: <NotificationsIcon className="icon" />, label: 'Notification', navigateTo: '/notifications' },
];

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [navItems, setNavItems] = useState([...initialNavItems]);
  const { notifications } = useContext(NotificationContext);
  const navigate = useNavigate();

  // Check if there are unread notifications
  const hasUnreadNotifications = notifications.some(notification => !notification.read);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const jsonValue = localStorage.getItem('userData');
        if (jsonValue) {
          setUserData(JSON.parse(jsonValue));
        }
      } catch (e) {
        console.error('Error fetching user data:', e);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      const { pstatus, status, apstatus, id } = userData;
      const isEligibleForCertificate = pstatus === 'P' && status === 'M' && apstatus === 'A';
      const newNavItems = [...initialNavItems];

      if (isEligibleForCertificate && !newNavItems.some(item => item.label === 'Download Certificate')) {
        newNavItems.push({
          icon: <CertificateIcon className="icon" />,
          label: 'Download Certificate',
          navigateTo: `/certificate/${id}`,
        });
      }

      if (apstatus === 'A' && pstatus === 'P' && !newNavItems.some(item => item.label === 'Chat')) {
        newNavItems.push({
          icon: <ChatIcon className="icon" />,
          label: 'Chat',
          navigateTo: '/chatpage',
        });
      }

      setNavItems(newNavItems);
    }
  }, [userData]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userData');
    navigate('/', { replace: true });
  };

  const handleNavItemClick = (path) => {
    navigate(path);
    handleMobileMenuClose();
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); window.location.href = 'https://www.worldodishasociety.org/'; }}>Home</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="mobile-menu"
    >
      {navItems.map((item, index) => (
        <MenuItem key={index} onClick={() => handleNavItemClick(item.navigateTo)}>
          <IconButton color="inherit">
            {item.icon}
            {/* Red dot for notifications */}
            {item.label === 'Notification' && hasUnreadNotifications && (
              <span className="red-dot"></span>
            )}
          </IconButton>
          <Typography variant="body2">{item.label}</Typography>
        </MenuItem>
      ))}
      <MenuItem onClick={() => { handleMobileMenuClose(); window.location.href = 'https://www.worldodishasociety.org/'; }}>
        <IconButton style={{ color: '#2393e8' }}>
          <HomeIcon />
        </IconButton>
        <Typography variant="body2">Home</Typography>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <IconButton style={{ color: 'red' }}>
          <ExitToAppIcon />
        </IconButton>
        <Typography variant="body2" style={{ color: 'red' }}>Sign Out</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="header">
      <Toolbar>
        <div className="header-content">
          <div className="header-logo">
            <a className="header-logo__link">
              <img className="header-logo__img" src={logo} alt="logo" />
            </a>
          </div>
          <div className="navbar-container">
            <div className="navbar-items">
              {navItems.map((item, index) => (
                <a className="navbar-item" href={item.navigateTo} key={index} onClick={(e) => {
                  e.preventDefault();
                  navigate(item.navigateTo);
                }}>
                  <span className="icon">{item.icon}</span>
                  <span className="text notification-text">
                    {item.label}
                    {/* Red dot for notifications */}
                    {item.label === 'Notification' && hasUnreadNotifications && (
                      <span className="red-dot"></span>
                    )}
                  </span>
                </a>
              ))}
              <Hidden smDown>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="inherit"
                  className="hide-on-mobile"
                >
                  <AccountCircle />
                </IconButton>
              </Hidden>
            </div>
          </div>
          <Hidden mdUp>
            <div className="mobile-menu-icon">
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
                {hasUnreadNotifications && (
                  <span className="red-dot-mobile"></span>
                )}
              </IconButton>
            </div>
          </Hidden>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default Navbar;
