import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock, FaEye, FaEyeSlash,FaUserPlus, FaGoogle, FaFacebookF, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import axios from 'axios';
import './style.css';
import { gapi } from 'gapi-script';

const clientId = '88394316372-3tborqckf88ve7nro7ja3or2pit9qltu.apps.googleusercontent.com';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isGapiReady, setIsGapiReady] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [passwordVerify, setPasswordVerify] = useState(false);

  // Clear state on component mount to prevent autofill
  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  // Validate email and password
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailVerify(emailRegex.test(email));

    setPasswordVerify(password.length >= 6);
  }, [email, password]);

  const handleSubmit = async () => {
    if (!emailVerify || !passwordVerify) {
      alert('Please enter valid email and password.');
      return;
    }

    try {
      const response = await axios.get(
        `https://wosapi.essarsystems.co.in/wosapi_login?id=${email}`,
        { params: { email, password } }
      );

      if (response.data && response.data.password === password) {
        localStorage.setItem('userData', JSON.stringify(response.data));
        localStorage.setItem('isLoggedIn', JSON.stringify(true));
        alert('Logged In Successfully');
        navigate('/profile');
      } else {
        alert('Login failed: Invalid email or password');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Enter correct Email and Password');
    }
  };

  // Initialize Google API client
  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          clientId: clientId,
          scope: 'openid profile email',
        })
        .then(() => {
          setIsGapiReady(true);
          console.log('Google API initialized successfully');
        })
        .catch((error) => {
          console.error('Error initializing Google API:', error);
          alert('Failed to initialize Google API');
        });
    };

    console.log('Loading Google API client');
    gapi.load('client:auth2', initClient);
  }, []);

  // Handle Google login
  const handleGoogleLogin = async () => {
    if (isGapiReady) {
      const authInstance = gapi.auth2.getAuthInstance();
      if (authInstance) {
        try {
          const user = await authInstance.signIn();
          const profile = user.getBasicProfile();
          const response = {
            profileObj: {
              name: profile.getName(),
              email: profile.getEmail(),
              imageUrl: profile.getImageUrl(),
            },
          };
          await onSuccess(response);
        } catch (error) {
          onFailure(error);
        }
      } else {
        console.error('Google Auth Instance is not available');
        alert('Google Auth Instance is not available');
      }
    } else {
      console.error('Google Auth is not initialized');
      alert('Google Auth is not initialized');
    }
  };

  // Handle successful Google login
  const onSuccess = async (response) => {
    const { email, name } = response.profileObj;

    try {
      // Check if the user exists in the database
      const checkResponse = await axios.get(
        `https://wosapi.essarsystems.co.in/wosapi_login`,
        {
          params: { id: email },
        }
      );

      if (checkResponse.data && checkResponse.data.email === email) {
        // User found, log them in
        console.log('User found:', checkResponse.data);
        localStorage.setItem('userData', JSON.stringify(checkResponse.data));
        localStorage.setItem('isLoggedIn', JSON.stringify(true));
        alert('Logged In Successfully');
        navigate('/profile');
      } else {
        // User not found, register them
        const newUser = {
          fname: name.split(' ')[0],
          lname: name.split(' ')[1] || '',
          email,
          mobile: '',
          password: '',
          status: 'S',
        };

        console.log('Attempting to register new user:', newUser);

        try {
          const registerResponse = await axios.post(
            'https://wosapi.essarsystems.co.in/wos_restapi',
            newUser
          );

          if (registerResponse.data && registerResponse.data.id) {
            console.log('User registered successfully:', registerResponse.data);
            localStorage.setItem('userData', JSON.stringify(registerResponse.data));
            localStorage.setItem('isLoggedIn', JSON.stringify(true));
            alert('Registered and Logged In Successfully');
            navigate('/profile');
          } else {
            console.error('Registration failed:', registerResponse.data);
            alert('Registration failed. Please try again.');
          }
        } catch (registerError) {
          console.error(
            'Error during registration:',
            registerError.response ? registerError.response.data : registerError.message
          );
          alert('An error occurred during registration.');
        }
      }
    } catch (error) {
      console.error('Error during login/registration:', error.response ? error.response.data : error.message);
      alert('An error occurred during login or registration.');
    }
  };

  // Handle failed Google login
  const onFailure = (error) => {
    console.error('Google Login Failed:', error);
    alert(`Google Login Failed: ${error.error}`);
  };

  return (
    <div className="container">
      <div className="logo-container">
        <img
          className="logo"
          src={require('../../assets/logo_dark.png')}
          alt="Logo"
        />
      </div>
      <div className="form-container">
        <h1 className="header-text">Login</h1>
        <div className="input-container">
          <FaUser className="icon" />
          <input
            type="email"
            placeholder="Enter Your Email"
            className="text-input"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoComplete="off"
          />
          {email.length > 0 && (emailVerify ? <FaCheckCircle className="valid-icon" /> : <FaExclamationCircle className="error-icon" />)}
        </div>
        {email.length > 0 && !emailVerify && <p className="error-text">Enter a valid email address</p>}
        <div className="input-container">
          <FaLock className="icon" />
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Enter Your Password"
            className="text-input"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            autoComplete="new-password"
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? (
              <FaEye className={passwordVerify ? 'valid-icon' : 'error-icon'} />
            ) : (
              <FaEyeSlash className={passwordVerify ? 'valid-icon' : 'error-icon'} />
            )}
          </button>
        </div>
        {password.length > 0 && !passwordVerify && <p className="error-text">Password must be at least 6 characters</p>}
        <div className="button-container">
          <button className="submit-button" onClick={handleSubmit}>
            Login
          </button>
        </div>
        <div className="alternate-login">
          <p>----Or Continue as----</p>
        </div>
        <div className="bottom-button">
          <div className="icon-container">
            <button
              className="in-but2"
              onClick={() => navigate('/register')}
            >
              <FaUserPlus className="icon" />
            </button>
            <p className="bottom-text">Subscribe Now</p>
          </div>
          <div className="icon-container">
            <button className="in-but2" onClick={() => alert('Coming Soon')}>
              <FaGoogle className="icon" />
            </button>
            <p className="bottom-text">Google</p>
          </div>
          <div className="icon-container">
            <button className="in-but2" onClick={() => alert('Coming Soon')}>
              <FaFacebookF className="icon" />
            </button>
            <p className="bottom-text">Facebook</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
