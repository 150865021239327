import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './Screens/Login&Register/Login';
import RegisterPage from './Screens/Login&Register/Register';
import ProfilePage from './Screens/ProfilePage';
import UpdateProfile from './Screens/UpdateProfile';
import MemberForm from './Screens/MemberForm';
import DownloadCertificateScreen from './Screens/DownloadCertificateScreen';
import MemberList from './Screens/MemberList';
import Chat from './Screens/Chat';
import ChangePassword from './Screens/ChangePassword';
import ChatPage from './Screens/ChatPage';
import PaymentPage from './Screens/PaymentPage';
 import NotificationDemo from './Screens/NotificationDemo';
 import Navbar from './Components/Navbar';
// import PaymentPage from './Screens/paymentDemo';
//import ChatComponent from './Screens/ChatComponent';
import { NotificationProvider } from './Screens/NotificationContext';
import { UserProvider } from './Screens/UserContext';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const authStatus = localStorage.getItem('isLoggedIn');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

   // Conditionally render the Navbar except on the payment page, login page, and register page
  const shouldShowNavbar = !['/paymentpage', '/', '/register'].includes(location.pathname);

  return (
    
     <UserProvider>
     <NotificationProvider>
     {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Navigate to="/profile" />} />
       <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/change-password" element={<ChangePassword />} />
       <Route path="/member-form" element={<MemberForm />} />
       {/* <Route path="/paymentpage" element={<PaymentPage />} /> */}
       <Route path="/paymentpage" element={<PaymentPage />} />
       <Route path='/notifications' element={<NotificationDemo />} />
       <Route path='/chatpage' element={<ChatPage />} />
       <Route path="/member-list" element={<MemberList />} />
         <Route path="/chat/:receiverId" element={<Chat />} />
        <Route path="/certificate/:userId" element={<DownloadCertificateScreen />} /> 
      </Routes>
      </NotificationProvider>
      </UserProvider>
   
  );
}

export default App;
