import React, { useState, useEffect, useRef } from 'react';
import { getFirestoreDb } from '../Utils/firebaseInitialization';
import { collection, addDoc, serverTimestamp, onSnapshot, orderBy, query, doc, deleteDoc } from 'firebase/firestore';
import { TextField, Button, ListItemText } from '@mui/material';
import ChatHeader from '../Screens/ChatComponents/ChatHeader';
import DeletePopup from '../Screens/ChatComponents/DeletePopup';
import './Chat.css';

const db = getFirestoreDb();

const Chat = ({ receiverId, countryName, memberName }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState('');
    const [chatRoomId, setChatRoomId] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const longPressTimeout = useRef(null);

    useEffect(() => {
        const getUserData = () => {
            try {
                const userData = localStorage.getItem('userData');
                if (userData !== null) {
                    const { id, fname } = JSON.parse(userData);
                    if (id && fname) {
                        setUserId(id);
                        setUserName(fname);
                        const roomId = [id, receiverId].sort().join('_');
                        setChatRoomId(roomId);
                    } else {
                        alert('Invalid user data');
                    }
                } else {
                    alert('User data not found');
                }
            } catch (error) {
                console.error('Error retrieving user data:', error);
                alert('An error occurred while retrieving user data');
            }
        };
        getUserData();
    }, [receiverId]);

    useEffect(() => {
        if (!chatRoomId) return;

        const querySnapShot = query(
            collection(db, 'chats', chatRoomId, 'messages'),
            orderBy('createdAt')
        );

        const unsubscribe = onSnapshot(querySnapShot, snapShot => {
            const allMessages = snapShot.docs.map(snap => {
                const data = snap.data();
                return {
                    ...data,
                    _id: snap.id,
                    createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
                };
            });
            setMessages(allMessages);
        });

        return () => unsubscribe();
    }, [chatRoomId]);

    const sendMessageToFirestore = async (message) => {
        try {
            await addDoc(collection(db, 'chats', chatRoomId, 'messages'), {
                ...message,
                createdAt: serverTimestamp(),
            });
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred while sending the message');
        }
    };

    const handleSendMessage = () => {
        if (!newMessage.trim()) return;

        const message = {
            text: newMessage,
            user: {
                _id: userId,
                name: userName,
            },
            receiverId,
            createdAt: new Date(),
        };

        setNewMessage('');
        sendMessageToFirestore(message);
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            await deleteDoc(doc(db, 'chats', chatRoomId, 'messages', messageId));
            setMessages(previousMessages => previousMessages.filter(message => message._id !== messageId));
        } catch (error) {
            console.error('Error deleting message:', error);
            alert('An error occurred while deleting the message');
        }
    };

    const handleDeleteMessageForMe = (messageId) => {
        setMessages(previousMessages => previousMessages.filter(message => message._id !== messageId));
    };

    const openDeletePopup = (message) => {
        setSelectedMessage(message);
        setPopupVisible(true);
    };

    const closeDeletePopup = () => {
        setPopupVisible(false);
        setSelectedMessage(null);
    };

    const handleDeleteForEveryone = () => {
        if (selectedMessage) {
            handleDeleteMessage(selectedMessage._id);
            closeDeletePopup();
        }
    };

    const startLongPress = (message) => {
        longPressTimeout.current = setTimeout(() => {
            openDeletePopup(message);  // Open the delete popup directly on long press
        }, 600); // Time in milliseconds for long press
    };

    const endLongPress = () => {
        clearTimeout(longPressTimeout.current);
    };

    const formatDate = (date) => {
        const now = new Date();
        const diffTime = now - new Date(date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays < 1) return 'Today';
        if (diffDays < 2) return 'Yesterday';
        return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short' }).format(new Date(date));
    };

    const groupedMessages = messages.reduce((acc, message) => {
        const date = formatDate(message.createdAt);
        if (!acc[date]) acc[date] = [];
        acc[date].push(message);
        return acc;
    }, {});

    return (
        <div className="chatContainer">
            <ChatHeader userName={memberName} profilePic="https://example.com/profile.jpg" />
            <div className="chatInfo">
                <p>Chatting with {memberName} from {countryName}</p>
            </div>
            <div className="messageList">
                {Object.keys(groupedMessages).map(date => (
                    <div key={date}>
                        <div className="dateHeader">{date}</div>
                        <div className="messageGroup">
                            {groupedMessages[date].map(message => (
                                <div
                                    key={message._id}
                                    className={`message ${message.user._id === userId ? 'myMessage' : 'theirMessage'}`}
                                    onMouseDown={() => startLongPress(message)}
                                    onMouseUp={endLongPress}
                                    onTouchStart={() => startLongPress(message)}
                                    onTouchEnd={endLongPress}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        openDeletePopup(message);
                                    }}
                                >
                                    <ListItemText
                                        primary={message.text}
                                        secondary={
                                            <div className="messageTimestamp">
                                                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </div>
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="messageInputContainer">
                <TextField
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    label="Type a message"
                    variant="outlined"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleSendMessage}>
                    Send
                </Button>
            </div>
            <DeletePopup
                visible={popupVisible}
                onClose={closeDeletePopup}
                onDeleteForMe={() => {
                    if (selectedMessage) {
                        handleDeleteMessageForMe(selectedMessage._id);
                        closeDeletePopup();
                    }
                }}
                onDeleteForEveryone={handleDeleteForEveryone}
                showDeleteForEveryone={selectedMessage?.user._id === userId}
            />
        </div>
    );
};

export default Chat;
