import React from 'react';
import PropTypes from 'prop-types';
import './DeletePopup.css';

const DeletePopup = ({ visible, onClose, onDeleteForMe, onDeleteForEveryone, showDeleteForEveryone }) => {
  if (!visible) return null;

  return (
    <div className="overlay">
      <div className="popup">
        <button onClick={onDeleteForMe} className="button">
          Delete for Me
        </button>
        {showDeleteForEveryone && (
          <button onClick={onDeleteForEveryone} className="button">
            Delete for Everyone
          </button>
        )}
        <button onClick={onClose} className="button">
          Cancel
        </button>
      </div>
    </div>
  );
};

DeletePopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteForMe: PropTypes.func.isRequired,
  onDeleteForEveryone: PropTypes.func,
  showDeleteForEveryone: PropTypes.bool,
};

DeletePopup.defaultProps = {
  onDeleteForEveryone: () => {},
  showDeleteForEveryone: false,
};

export default DeletePopup;
