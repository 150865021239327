import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './MemberForm.css';
import Navbar from '../Components/Navbar';
import TermsAndConditionsPopup from './MemberformComponents/TermsAndConditionsPopup';
import BenefitsPopup from './MemberformComponents/BenefitsPopup';

const MemberForm = () => {
  const [selectedTitle, setSelectedTitle] = useState('');
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [addr1, setAddress1] = useState('');
  const [addr2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPostCode] = useState('');
  const [membership_type, setMembershipType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [country, setCountries] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchCountries();
    getUserData();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v2/all');
      const data = await response.json();
      const countryNames = data.map((country) => country.name);
      setCountries(countryNames);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getUserData = () => {
    try {
      const data = localStorage.getItem('userData');
      if (data !== null) {
        const parsedData = JSON.parse(data);
        setUserData(parsedData);
        setFormData(parsedData); // Set form fields from user data
      } else {
        console.log('No user data found in localStorage');
      }
    } catch (error) {
      console.error('Error retrieving user data:', error);
    }
  };

  const setFormData = (data) => {
    setSelectedTitle(data.title || '');
    setFName(data.fname || '');
    setLName(data.lname || '');
    setMobile(data.mobile || '');
    setPhoneNumber(data.phone || '');
    setEmail(data.email || '');
    setAddress1(data.addr1 || '');
    setAddress2(data.addr2 || '');
    setCity(data.city || '');
    setState(data.state || '');
    setSelectedCountry(data.country || '');
    setPostCode(data.pincode || '');
    setMembershipType(data.membership_type || null);
  };

  const handleOptionSelect = (e) => {
    setSelectedTitle(e.target.value);
  };

  const handleCountrySelect = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleMembershipTypeSelect = (e) => {
    setMembershipType(e.target.value);
  };

  const handleTermsAgreement = (agreed) => {
    setAgreeTerms(agreed);
    setErrorMessage(''); // Clear error message when agreement status changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userData.apstatus === 'A' && userData.pstatus === 'N') {
        alert('You are already approved by Admin');
        return;
      }
      if (userData.apstatus === 'A' && userData.pstatus === 'P') {
        alert('You are already a Member');
        return;
      }
      if (!fname) {
        setErrorMessage('Please fill First Name');
        return;
      }
      if (!lname) {
        setErrorMessage('Please fill Last Name');
        return;
      }
      if (!mobile) {
        setErrorMessage('Please fill Phone');
        return;
      }
      if (!addr1) {
        setErrorMessage('Please fill Address');
        return;
      }
      if (!email) {
        setErrorMessage('Please fill Email');
        return;
      }
      if (!city) {
        setErrorMessage('Please fill City');
        return;
      }
      if (!selectedCountry) {
        setErrorMessage('Please fill Country');
        return;
      }
      if (!pincode) {
        setErrorMessage('Please fill Post Code');
        return;
      }
      if (!membership_type) {
        setErrorMessage('Please fill Membership Type');
        return;
      }
      if (!agreeTerms) {
        setErrorMessage('Please agree to the terms and conditions');
        return;
      }

      const updatedUserData = {
        ...userData,
        title: selectedTitle,
        fname: fname,
        lname: lname,
        email,
        mobile: mobile,
        phone,
        addr1,
        addr2,
        city,
        state,
        country: selectedCountry,
        pincode,
        membership_type,
        status: 'M',
        apstatus: userData.apstatus || 'P',
        pstatus: userData.pstatus || 'N',
        certificate_date: userData.certificate_date,
        memno: userData.memno,
      };

      console.log('Updated User Data:', updatedUserData);

      const response = await axios.put(
        'https://wosapi.essarsystems.co.in/wos_restapi',
        updatedUserData
      );

      setUserData(updatedUserData);
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      // Implement navigation if needed

    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
      if (error.response) {
        console.error('Server Error:', error.response.data);
      } else if (error.request) {
        console.error('No response from server:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  };

  return (
    <div>
    {/* <Navbar /> */}
    <div className="container">
      <header className="head">
        <h1 className="fheading">Membership Registration Form</h1>
      </header>
      <div className="mform-container">
        <form onSubmit={handleSubmit}>
          <label>
            Title
            <select
              className="dropdownButton"
              value={selectedTitle}
              onChange={handleOptionSelect}
            >
              <option value="">Select Title</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Sir">Sir</option>
              <option value="Dr">Dr</option>
            </select>
          </label>
          <label>
            First Name
            <input
              className="phoneinput"
              type="text"
              value={fname}
              onChange={(e) => setFName(e.target.value)}
            />
          </label>
          <label>
            Last Name
            <input
              className="phoneinput"
              type="text"
              value={lname}
              onChange={(e) => setLName(e.target.value)}
            />
          </label>
          <label>
            Phone Number
            <PhoneInput
              className="phoneinput"
              country={'in'}
              value={phone}
              onChange={(value) => setPhoneNumber(value)}
            />
          </label>
          <label>
            Cell Phone
            <PhoneInput
              className="phoneinput"
              country={'in'}
              value={mobile}
              onChange={(value) => setMobile(value)}
            />
          </label>
          <label>
            Email Address
            <input
              className="phoneinput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </label>
          <label>
            Address Line 1
            <input
              className="phoneinput"
              type="text"
              value={addr1}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </label>
          <label>
            Address Line 2
            <input
              className="phoneinput"
              type="text"
              value={addr2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </label>
          <label>
            City
            <input
              className="phoneinput"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </label>
          <label>
            State
            <input
              className="phoneinput"
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </label>
          <label>
            Country
            <select
              className="dropdownButton"
              value={selectedCountry}
              onChange={handleCountrySelect}
            >
              <option value="">Select Country</option>
              {country.map((countryName) => (
                <option key={countryName} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>
          </label>
          <label>
            Post Code/Zip
            <input
              className="phoneinput"
              type="text"
              value={pincode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </label>
          <label>
            Membership Type
            <div className="membership-options">
              {['Annual (INR 3,000/-)', 'Life (INR 15,000/-)', 'Premium Life (INR 25,000/-)', 'Corporate (INR 1,000,00/-)', 'Donor (INR 1,000,00/- & Above Contribution)', 'Institution/ Organisation (INR 5,000,00/-)'].map(option => (
                <label key={option} className="radio-container">
                  <input
                    type="radio"
                    name="membership_type"
                    value={option}
                    checked={membership_type === option}
                    onChange={() => setMembershipType(option)}
                  />
                  <span className="radio-checkmark"></span>
                  {option}
                </label>
              ))}
            </div>
          </label>
          <TermsAndConditionsPopup onAgreementChange={handleTermsAgreement} />
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          <div className="subcontainer">
  <button className="inBut" type="submit">
    <span className="textSign">Submit</span>
  </button>
</div>
        </form>
        <BenefitsPopup />
      </div>
    </div>
    </div>
  );
};

export default MemberForm;
