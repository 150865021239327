import React, { useContext, useEffect } from 'react';
import { NotificationContext } from './NotificationContext';
import './NotificationDemo.css'; // Add a CSS file for styling

const NotificationsPage = () => {
  const { notifications, markAllAsRead } = useContext(NotificationContext);

  // Mark all notifications as read when the page loads
  useEffect(() => {
    markAllAsRead();
  }, [markAllAsRead]);

  // Log notifications for debugging
  useEffect(() => {
    console.log('Notifications:', notifications); // Debugging log
  }, [notifications]);

  if (notifications.length === 0) {
    return <p className="no-notifications">No notifications found.</p>;
  }

  return (
    <div className="notifications-page">
      <h1 className="page-title">Notifications</h1>
      {notifications.map((notif, index) => (
        <div key={index} className={`notification-item ${notif.read ? 'read' : 'unread'}`}>
          <h2 className="notification-title">{notif.title}</h2>
          <p className="notification-body">{notif.body}</p>
        </div>
      ))}
    </div>
  );
};

export default NotificationsPage;
